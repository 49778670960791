import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'no-overdue-depts-content',
  templateUrl: './no-overdue-depts-content.component.html',
  styleUrls: ['./no-overdue-depts-content.component.css']
})

export class NoOverdueDeptsContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
