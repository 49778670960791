export enum ClientLoanStateTypes {
  Requested = 'Requested',
  Approved = 'Approved',
  Confirmed = 'Confirmed',
  Current = 'Current',
  Late = 'Late',
  Repaid = 'Repaid',
  Rejected = 'Rejected',
  Court  = 'Court',
  CourtNew  = 'CourtNew',
  CourtStaging  = 'CourtStaging',
  Fraud  = 'Fraud',
  PreSchedule  = 'PreSchedule',
  R66  = 'R66',
  Scheduled  = 'Scheduled',
  Scdeduledlate  = 'Scdeduledlate',
  InHousecollection  = 'InHousecollection',
  InternalFault  = 'InternalFault',
}
