export enum AccountPopupTypes {
  Name = 1,
  Email,
  Phone,
  Password,
  CreatePassword,
  Passport,
  BankAccount,
  DeclaredAddress,
  ActualAddress,
  AcceptanceInfo,
  WindowAcceptance,
  SmsAcceptance,
  RefinanceInfo,
  ScheduleBankInfo,
  RefinanceConsentsAcceptance,
  Question,
  MarketingAccept
}
