import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {ApiService, AuthService, SecureHttpClientService} from '../../services';
import {
  AuthenticationActionTypes,
  LoadUserData,
  LogIn,
  SetAuthenticationError,
  SetAuthenticationUserData,
  SetSignUpIntervalData,
  SignUpStep1,
  SignUpError,
  SignUpStep2,
  RestorePassByPhone,
  RestorePassSuccess,
  RestorePassError,
  RestorePassByEmail,
  LogOut,
  CleanUser,
  OpenModal,
  SetCommonErrorMessage,
  SignUpComplete,
  SetFormFieldErrors, SetCalculatorLoadStatus, LoadConditions, SetCalculatorAmount, LoadLoanCurrentInfo, SetCalculatorTerm,
} from '../actions';
import {catchError, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {AppState} from '../state';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {normalizeErrorString} from '../../utils/common.utils';
import {ModalTypes} from '../../enums';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { globalVariables } from 'src/environments/globalVariables';


@Injectable()
export class AuthenticationEffects {

  @Effect() logIn$ = this.actions$
    .pipe(
      ofType<LogIn>(AuthenticationActionTypes.LogIn),
      switchMap((action) => this.authService.login({
          login: action.payload.username,
          password: action.payload.password,
          isMobile: action.payload.isMobile
        })
          .pipe(
            switchMap((response: any) => {
              if (!response.success) {
                return of(new SetAuthenticationError({error: normalizeErrorString(response.errorMessage)}));
              }

              console.log('LogIn success');

              this.authService.setExpiredToken((String)((new Date()).getTime() + environment.tokenExpireTime));
              this.authService.setAuthToken(response.accessToken);

              const {id, accessToken, firstName, lastName, error} = response;

              const action1 = new SetAuthenticationUserData({
                id,
                userName: firstName + ' ' + lastName,
                error,
              });
              const action2 = new LoadUserData({token: response.accessToken});
              //const action3 = new LoadConditions();
              const action4 = new SetCalculatorAmount(undefined);
              const action5 = new SetCalculatorTerm(undefined, undefined, undefined);
              
              return of(action1, action2, action4, action5);
            }),
            catchError((errData: any) => of(new SetAuthenticationError({error: normalizeErrorString(errData.errorMessage)}))),
          )
      ),
    );

  @Effect()
  logOut$ = this.actions$
    .pipe(
      ofType<LogOut>(AuthenticationActionTypes.LogOut),
      mergeMap(action => {
        this.authService.setAuthToken(null);
        this.authService.setExpiredToken(null);

        if (navigator.cookieEnabled) {
          localStorage.removeItem('redirected');
        } else {
          // tslint:disable-next-line:no-unused-expression
          globalVariables.redirected = null;
        }

        if (navigator.cookieEnabled) {
          localStorage.setItem('Logout', '1');
        } else {
          globalVariables.Logout = '1';
        }
  
        window.location.reload();

        // this.router.navigate(['/']);
        return of(new LoadConditions(), new CleanUser());
      })
    );

  @Effect()
  signUpStep1$ = this.actions$
    .pipe(
      ofType<SignUpStep1>(AuthenticationActionTypes.SignUpStep1),
      switchMap((action) => {
        const reqObj = {
          phone: action.payload.regData.phone.substring(2),
          email: action.payload.regData.email,
        };
        return this.http.post('eb7133f2-963a-4b01-aa45-c1fac90d03e6/CheckCustomer', {...reqObj})
          .pipe(
            // @ts-ignore
            switchMap((resp: any) => {
              if (resp.success) {
                return of(new SetSignUpIntervalData({email: '', token: '', id: ''}));
              } else {
                return this.handleAuthOrRegError(resp.validationErrors);
              }
            }),
            catchError(errMessage => this.handleCommonError(errMessage.errorMessage))
          );
      })
    );

  @Effect()
  signUpStep2$ = this.actions$
    .pipe(
      ofType<SignUpStep2>(AuthenticationActionTypes.SignUpStep2),
      switchMap((action) => {
        return this.http.post('eb7133f2-963a-4b01-aa45-c1fac90d03e6/Register', {
          ...action.payload.completeRegData,
        })
          .pipe(
            switchMap(resp => {

              this.spinner.hide();

              try {
                this.http.post('eb7133f2-963a-4b01-aa45-c1fac90d03e6/LogRegistrationData', {
                  data: JSON.stringify(action.payload.completeRegData),
                  transactionid: navigator.cookieEnabled ? localStorage.getItem('transactionid') : globalVariables.transactionid,
                  affiliateid: navigator.cookieEnabled ? localStorage.getItem('affiliateid') : globalVariables.affiliateid,
                  utm_source: navigator.cookieEnabled ? localStorage.getItem('utm_source') : globalVariables.utm_source,
                  utm_content: navigator.cookieEnabled ? localStorage.getItem('utm_content') : globalVariables.utm_content,
                  type: 'step2',
                  success: resp.success,
                  errors: JSON.stringify(resp.validationErrors),
                  accessToken: resp.accessToken
                }).subscribe();
              } catch { }

              if (!resp.success) {

                return this.handleAuthOrRegError(resp.validationErrors);
/*
                const action1 = new SignUpError({
                  errMessage: normalizeErrorString(resp.errorMessage),
                  errors: resp.validationErrors ? resp.validationErrors : null
                });
             //   const action2 = new OpenModal(ModalTypes.CommonErrorModal);
                return of(action1);*/
              }

             // window.open('/warunki-umowy', '_blank');

              return of(new SignUpComplete({
                token: resp.accessToken,
                email: action.payload.completeRegData.customerData.email,
                password: action.payload.completeRegData.customerData.password
              }));
              // this.http.post('eb7133f2-963a-4b01-aa45-c1fac90d03e6/CompleteRegistration', {
              //   accessToken: resp.accessToken
              // }).subscribe(data => {
              //   console.log(data);
              //   if (data.success) {
              //     return new LogIn({
              //       username: action.payload.completeRegData.customerData.email,
              //       password: action.payload.completeRegData.customerData.password
              //     });
              //   } else {
              //     return new SignUpError({errMessage: normalizeErrorString(data.errorMessage), errors: data.validationErrors});
              //   }
              // });
            }),
            catchError(errMessage => this.handleCommonError(errMessage))
          );
      })
    );

  @Effect()
  signUpComplete$ = this.actions$
    .pipe(
      ofType<SignUpComplete>(AuthenticationActionTypes.SignUpComplete),
      mergeMap((action) => {
        return this.http.post('eb7133f2-963a-4b01-aa45-c1fac90d03e6/CompleteRegistration', {
          accessToken: action.payload.token
        })
          .pipe(
            switchMap((data: any) => {
              if (data.success) {
                return of(new LogIn({
                  username: action.payload.email,
                  password: action.payload.password,
                  isMobile: this.deviceService.isMobile()
                }));
              } else {
                return of(new SignUpError({
                  errMessage: normalizeErrorString(data.errorMessage),
                  errors: data.validationErrors
                }));
              }
            })
          );
        }
      )
    );

  @Effect()
  restorePassByEmail$ = this.actions$
    .pipe(
      ofType<RestorePassByEmail>(AuthenticationActionTypes.RestorePassByEmail),
      mergeMap(action => this.http.post('eb7133f2-963a-4b01-aa45-c1fac90d03e6/ResetPassword', {email: action.email})
        .pipe(
          map(resp => this.afterRestorePassword(resp)),
          catchError(errMessage => of(new RestorePassError({errMessage: errMessage.errorMessage})))
        )
      )
    );

  @Effect()
  restorePassByPhone$ = this.actions$
    .pipe(
      ofType<RestorePassByPhone>(AuthenticationActionTypes.RestorePassByPhone),
      mergeMap(action => this.http.post('eb7133f2-963a-4b01-aa45-c1fac90d03e6/ResetPassword', {phone: action.phone})
        .pipe(
          map(resp => this.afterRestorePassword(resp)),
          catchError(errMessage => of(new RestorePassError({errMessage: errMessage.error})))
        )
      )
    );

  constructor(private actions$: Actions,
              private authService: AuthService,
              private http: ApiService,
              private secHttp: SecureHttpClientService,
              private store: Store<AppState>,
              private router: Router,
              private deviceService: DeviceDetectorService,
              private spinner: NgxSpinnerService,
              private httpClient: HttpClient) {
  }

  private afterRestorePassword(resp) {
    if (!resp.success) {
      return new RestorePassError({errMessage: normalizeErrorString(resp.errorMessage)});
    }
    return new RestorePassSuccess();
  }

  private handleCommonError(errMessage: any) {
    let action1;
    let action2;
    if (errMessage.error || errMessage.errorMessage) {
      action1 = new SetCommonErrorMessage({
        errMessage: normalizeErrorString(errMessage.error ? errMessage.error : errMessage.errorMessage)
      });
      action2 = new OpenModal(ModalTypes.CommonErrorModal);
      return of(action1, action2);
    }
    action1 = new SetCommonErrorMessage({errMessage: 'Something went wrong! Please try again later!'});
    action2 = new OpenModal(ModalTypes.CommonErrorModal);
    return of(action1, action2);
  }

  private handleAuthOrRegError(errors: any) {
    return of(new SetFormFieldErrors(errors));
  }

}
