import { Action } from '@ngrx/store';
import {CompleteRegDataModel, RegDataModel} from '../../modules/static-pages/models/RegDataModel';
import {ChangingStateTypes} from '../../modules/user-panel/models/changing-state-types';

export enum AuthenticationActionTypes {
  LogIn = '[Authentication] Log In',
  LogOut = '[Authentication] Log Out',
  SetAuthenticationError = '[Authentication] Error',
  SetAuthenticationStatus = '[Authentication] Set authentication status',
  SetAuthenticationUserData = '[Authentication] Set authentication user data',
  SignUpStep1 = '[Authentication] User registration step1',
  SignUpStep2 = '[Authentication] User registration step2',
  SignUpComplete = '[Authentication] Complete registration',
  SignSuccess = '[Authentication] User registration success',
  SignError = '[Authentication] User registration error',
  RestorePassByPhone = '[Authentication] Restore pass by Phone',
  RestorePassByEmail = '[Authentication] Restore pass by Email',
  RestorePassSuccess = '[Authentication] Restore pass success',
  RestorePassError = '[Authentication] Restore pass error',
  SetRestorePassState = '[Authentication] Set Restore pass state',
  SetSignUpIntervalData = '[Authentication] Set sign up interval data',
  ResetAuthErrors = '[Authentication] Reset error messages',
  SetRegistrationStep = '[Authentication] Set Registration step',
  CompleteRegistration = '[Authentication] CompleteRegistration',
  SetFormFieldErrors = '[Authentication] Set form fields error'
}

export class LogIn implements Action {
  readonly type = AuthenticationActionTypes.LogIn;

  constructor(readonly payload: {username: string, password: string, isMobile: boolean}) {
  }
}

export class LogOut implements Action {
  readonly type = AuthenticationActionTypes.LogOut;
}

export class SetAuthenticationStatus implements Action {
  readonly type = AuthenticationActionTypes.SetAuthenticationStatus;

  constructor(readonly payload: {isAuthenticated: boolean, token: string}) {
  }
}

export class SetAuthenticationError implements Action {
  readonly type = AuthenticationActionTypes.SetAuthenticationError;

  constructor(readonly payload: {error: any}) {
  }
}

export class SetAuthenticationUserData implements Action {
  readonly type = AuthenticationActionTypes.SetAuthenticationUserData;

  constructor(readonly payload: {id: number, userName: string, error: any}) {
  }
}

export class SignUpStep1 implements Action {
  readonly type = AuthenticationActionTypes.SignUpStep1;

  constructor(readonly payload: {regData: RegDataModel}) {
  }
}

export class SignUpStep2 implements Action {
  readonly type = AuthenticationActionTypes.SignUpStep2;

  constructor(readonly payload: {completeRegData: CompleteRegDataModel}) {
  }
}

export class SignUpComplete implements Action {
  readonly type = AuthenticationActionTypes.SignUpComplete;

  constructor(readonly payload: {token: string, email: string, password: string}) {
  }
}

export class SignUpSuccess implements Action {
  readonly type = AuthenticationActionTypes.SignSuccess;
}

export class SignUpError implements Action {
  readonly type = AuthenticationActionTypes.SignError;

  constructor(readonly payload: {errMessage: any, errors?: any[]}) {
  }
}

export class RestorePassByPhone implements Action {
  readonly type = AuthenticationActionTypes.RestorePassByPhone;

  constructor(readonly phone: string) {
  }
}

export class RestorePassByEmail implements Action {
  readonly type = AuthenticationActionTypes.RestorePassByEmail;

  constructor(readonly email: string) {
  }
}

export class RestorePassSuccess implements Action {
  readonly type = AuthenticationActionTypes.RestorePassSuccess;
}

export class RestorePassError implements Action {
  readonly type = AuthenticationActionTypes.RestorePassError;

  constructor(readonly payload: {errMessage: any}) {
  }
}

export class SetSignUpIntervalData implements Action {
  readonly type = AuthenticationActionTypes.SetSignUpIntervalData;

  constructor(readonly payload: {email: string, id: string, token: string}) {
  }
}

export class SetRestorePassState implements Action {
  readonly type = AuthenticationActionTypes.SetRestorePassState;

  constructor(readonly state: ChangingStateTypes) {
  }
}

export class ResetAuthErrors implements Action {
  readonly type = AuthenticationActionTypes.ResetAuthErrors;
}

export class SetRegistrationStep implements Action {
  readonly type = AuthenticationActionTypes.SetRegistrationStep;

  constructor(readonly step: number) {
  }
}

export class CompleteRegistration implements Action {
  readonly type = AuthenticationActionTypes.CompleteRegistration;

  constructor(readonly token: string) {
  }
}

export class SetFormFieldErrors implements Action {
  readonly type = AuthenticationActionTypes.SetFormFieldErrors;
  constructor(readonly payload: [{Field: string, Description: string}]) {
  }
}

export type AuthenticationActions = LogIn |
  LogOut |
  SetAuthenticationUserData |
  SetAuthenticationStatus |
  SetAuthenticationError |
  SignUpStep1 |
  SignUpStep2 |
  SignUpComplete |
  SignUpError |
  SignUpSuccess |
  RestorePassByPhone |
  RestorePassByEmail |
  RestorePassSuccess |
  RestorePassError |
  SetRestorePassState |
  SetSignUpIntervalData |
  SetRegistrationStep |
  CompleteRegistration |
  ResetAuthErrors |
  SetFormFieldErrors;
