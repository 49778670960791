import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { LoadLoanHistory } from 'src/app/store/actions';
import { selectLoanHistory } from 'src/app/store/selectors';
import { selectCalculatorData } from 'src/app/store/selectors/info.selectors';
import { AppState } from 'src/app/store/state';

@Component({
  selector: 'app-regulamin-promocji-pozyczka-promocyjna-content',
  templateUrl: './regulamin-promocji-pozyczka-promocyjna-content.component.html',
  styleUrls: ['./regulamin-promocji-pozyczka-promocyjna-content.component.css']
})
export class RegulaminPromocjiPozyczkaPromocyjnaPageContentComponent implements OnInit {

  public repayment: Date;
  public maxAmount: number;

  constructor(private store: Store<AppState>) { 

    this.store.select(selectLoanHistory).subscribe(data => {
      this.repayment = data[data.length - 1].dates.repayment;
    } );

    this.store.select(selectCalculatorData)
    .pipe(
      tap()
    )
    .subscribe(data => {
      if (data && data.term && data.maxTerm && data.minTerm) {
        this.maxAmount = data.maxAmount;
      }
    });
  }

  ngOnInit() {
  }

}
