import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CloseModal } from 'src/app/store/actions';
import { AppState } from 'src/app/store/state';

@Component({
  selector: 'app-marketing-partner-agreement-content',
  templateUrl: './marketing-partner-agreement-content.component.html',
  styleUrls: ['./marketing-partner-agreement-content.component.css']
})
export class MarketingPartnerAgreementContentComponent implements OnInit {

  constructor(private store: Store<AppState>,
    private router: Router) { }

  public redirectToPartners() {
    this.router.navigateByUrl('/lista-partnerow-biznesowych');
    this.store.dispatch(new CloseModal());
  }


  ngOnInit() {
  }

}
