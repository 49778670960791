import {initialNavigationState, NavState} from '../state/navigation.state';
import {NavigationActions, NavigationActionTypes} from '../actions';

export function navigationReducer(state: NavState = initialNavigationState, action: NavigationActions): NavState {
  switch (action.type) {
    case NavigationActionTypes.SetNavigation:
      return {
        ...state,
        navItems: action.payload.navigationData
      };
    case NavigationActionTypes.SetUserNavigation:
      return {
        ...state,
        userNavItems: action.payload.userNavigationData
      };
    case NavigationActionTypes.SetFooterNavigation:
      return {
        ...state,
        footerNavItems: action.payload.footerNavigationData
      };
    case NavigationActionTypes.SetFooterSecondaryNavigation:
      return {
        ...state,
        footerSecondaryNavItems: action.payload.footerSecondaryNavigationData
      };
    case NavigationActionTypes.SetNavigationError:
      return {
        ...state,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
