// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// tslint:disable-next-line:one-variable-per-declaration
export let globalVariables = {
  auth_token: null,
// tslint:disable-next-line:indent
	expired_token: null,
  Logout: null,
  transactionid: null,
  affiliateid: null,
  utm_source: null,
  utm_content: null,
  redirected: null,
  FinSasAff: null,
  FinSasClientReferrer: null,
  redirectToProfile: null,
  resignnews: null,
  AllowConfirmLoan: true,
  blueMediaRedirect: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
