import { Action } from '@ngrx/store';

export enum ModalActionTypes {
  OpenModal = '[Modal] Open modal',
  CloseModal = '[Modal] Close modal',
}

export class OpenModal implements Action {
  readonly type = ModalActionTypes.OpenModal;

  constructor(readonly payload: number) {
  }
}

export class CloseModal implements Action {
  readonly type = ModalActionTypes.CloseModal;
}


export type ModalsActions = OpenModal | CloseModal;
