import { Component, OnInit, ViewChild } from '@angular/core';
import { CalculatorComponent } from '../calculator/calculator.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  @ViewChild(CalculatorComponent, {static: false})
  Calculator: CalculatorComponent;
  constructor() { }

  ngOnInit() {

  }

}
