import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import localePl from '@angular/common/locales/pl';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from './modules/shared/shared.module';
import {StoreModule} from '@ngrx/store';
import {reducers, metaReducers} from './store/reducers';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';
import {EffectsModule} from '@ngrx/effects';
import {AuthenticationEffects, InfoEffects, NavigationEffects, UserEffects} from './store/effects';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MobileMenuComponent} from './components/mobile-menu/mobile-menu.component';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {registerLocaleData} from '@angular/common';
import {NavigationService} from './services/navigationService';
import {NoAuthGuard} from './guards/auth.guard';
import {ServiceWorkerModule} from '@angular/service-worker';
import {RouterModule} from '@angular/router';
import {AddAuthorizationHeaderInterceptorService} from './interceptors/add-authorization-header-interceptor.service';
import {AddCsrfHeaderInterceptorService} from './interceptors/add-csrf-header-interceptor.service';
import {NotFoundPageComponent} from './modules/static-pages/not-found-page/not-found-page.component';
import { CookieService } from 'ngx-cookie-service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AcceptCookiesComponent } from './components/accept-cookies/accept-cookies.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxCookiebotModule } from '@halloverden/ngx-cookiebot';
import { CookiebotConfig } from '../environments/cookiebot.config';
import { NgxSpinnerModule } from 'ngx-spinner';

registerLocaleData(localePl, 'pl');

@NgModule({
  entryComponents: [],
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AcceptCookiesComponent,
    MobileMenuComponent,
    NotFoundPageComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      }
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([
      NavigationEffects,
      AuthenticationEffects,
      UserEffects,
      InfoEffects,
    ]),
    // ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    RouterModule,
    NgxCookiebotModule.forRoot(CookiebotConfig),
    NgxSpinnerModule,
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'pl'},
    NavigationService,
    NoAuthGuard,
    {provide: HTTP_INTERCEPTORS, useClass: AddAuthorizationHeaderInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AddCsrfHeaderInterceptorService, multi: true},
    CookieService,
    DeviceDetectorService,
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
