import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ModalTypes } from 'src/app/enums';
import { OpenModal } from 'src/app/store/actions';
import { SetContentPage } from 'src/app/store/actions/info.actions';
import { AppState } from 'src/app/store/state';

@Component({
  selector: 'app-accept-cookies',
  templateUrl: './accept-cookies.component.html',
  styleUrls: ['./accept-cookies.component.scss']
})
export class AcceptCookiesComponent implements OnInit {

  constructor(private cookieService: CookieService,
              private store: Store<AppState>,
              private deviceService: DeviceDetectorService) {

  }

  private alertConfirmed = 'cookie-alert-confirmed';

  public dataCookieAlert = false;
  public mobile = false;


  ngOnInit() {

    this.mobile = this.deviceService.isMobile();

    if (navigator.cookieEnabled) {
      if (this.cookieService.get(this.alertConfirmed) === '') {
        this.dataCookieAlert = true;
      }
    }
  }

  // tslint:disable-next-line:variable-name
  public ShowContentModal(_page: string) {
    this.store.dispatch(new SetContentPage({page: _page, parent: ModalTypes.None}));

    if (this.mobile) {
      this.store.dispatch(new OpenModal(ModalTypes.ContentMobile));
    } else {
      this.store.dispatch(new OpenModal(ModalTypes.Content));
    }
  }

  public closeCookieAlert() {
    if (navigator.cookieEnabled) {
      this.cookieService.set(this.alertConfirmed, 'confirmed');
      this.dataCookieAlert = false;
    }
  }

  public hideCookieAlert() {
    this.dataCookieAlert = false;
  }

}
