import {Injectable} from '@angular/core';
import {switchMap} from 'rxjs/operators';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SecureHttpClientService {

  constructor(private http: ApiService) {
  }

  public post<T>(url: string, data: any, headers?: any) {
    return this.http.get<T>(`account/antiforgery`).pipe(
      switchMap(_ => this.http.post<T>(url, data, headers)
      ));
  }

  public put<T>(url: string, data: any, headers?: any) {
    return this.http.get<T>(`account/antiforgery`).pipe(
      switchMap(_ => this.http.put(url, data, headers)
      ));
  }

  public delete<T>(url: string, data: any = null) {
    return this.http.get<T>(`account/antiforgery`).pipe(
      switchMap(_ => this.http.delete(url, data))
    );
  }
}
