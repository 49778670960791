import {initialModalsState, ModalsState} from '../state/modals.state';
import {ModalActionTypes, ModalsActions} from '../actions';

export function modalsReducer(state: ModalsState = initialModalsState, action: ModalsActions): ModalsState {
  switch (action.type) {
    case ModalActionTypes.OpenModal:
      document.body.classList.add('no-scroll');
      return {
        ...state,
        openedModalType: action.payload
      };
    case ModalActionTypes.CloseModal:
      document.body.classList.remove('no-scroll');
      return {
        ...state,
        openedModalType: null
      };
    default:
      return state;
  }
}
