import {Injectable} from '@angular/core';

@Injectable()
export class TransferService {

  constructor() {
  }

  private data;

  public setData(data) {
    this.data = data;
  }

  public getData() {
    this.clearData();
    return this.data;
  }

  public clearData() {
    this.data = undefined;
  }

}
