import {ChangeDetectionStrategy, Component, HostListener, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../../services';
import {Router} from '@angular/router';
import {AppState} from '../../../../../store/state';
import {Store} from '@ngrx/store';
import {CloseModal, LogIn, OpenModal, SetAuthenticationError, SubmitFeedback, SetCommonErrorMessage} from '../../../../../store/actions';
import {emailPattern} from '../../../../../utils';
import {ModalTypes} from '../../../../../enums';
import {BehaviorSubject, Observable} from 'rxjs';
import {selectCommonErrorMessage} from '../../../../../store/selectors/info.selectors';

@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedbackModalComponent implements OnInit {

  public feedbackForm: FormGroup;
  public isSubmitted: boolean;
  public errText: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private auth: AuthService,
              private router: Router,
              private frmBuilder: FormBuilder,
              private store: Store<AppState>) {
  }

  ngOnInit() {
    this.feedbackForm = this.frmBuilder.group({
      email: ['', [Validators.required, Validators.pattern(emailPattern)]],
      name: ['', [Validators.required]],
      message: ['', [Validators.required]]
    });    
    this.store.select(selectCommonErrorMessage).subscribe(error => {
      this.errText.next(error);
    });
  }

  get email() {
    return this.feedbackForm.get('email');
  }

  get name() {
    return this.feedbackForm.get('name');
  }

  get message() {
    return this.feedbackForm.get('message');
  }

  public feedback() {
    this.isSubmitted = true;

    if (this.feedbackForm.valid)
    {
      const {email, name, message} = this.feedbackForm.value;

      this.store.dispatch(new SubmitFeedback({
        name,
        email,
        feedback: message
      }));
    }
    else
    {
      this.errText = new BehaviorSubject<string>('');
    }
  }

  public unsubmit(event) {
    this.isSubmitted = false;
    this.errText = new BehaviorSubject<string>('');

    if (event.target.id === 'feedbackMail') {
      if (this.feedbackForm.controls.email.value.length > 0) {

        this.feedbackForm.controls.email.setValue(this.feedbackForm.controls.email.value[0].toLowerCase() +
            this.feedbackForm.controls.email.value.substr(1, this.feedbackForm.controls.email.value.length - 1));
      }
    }
  }

  public close = () => {
    this.store.dispatch(new CloseModal());
    this.store.dispatch(new SetCommonErrorMessage({errMessage: ''}));
  }
}
