import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AddAuthorizationHeaderInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req);
    /*
    const authToken = localStorage.getItem('auth_token');
    if (authToken) {
      if (['DELETE'].includes(req.method)) {
        return next.handle(req.clone({
          headers: req.headers.set('Authorization', `Bearer ${authToken}`)
        }));
      }
    }

    return next.handle(req);
     */
  }
}
