import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SecureHttpClientService} from '../../../../../services';
import {emailPattern, showErrorsFromServer} from '../../../../../utils';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../store/state';
import {OpenModal, RestorePassByEmail, RestorePassByPhone, SetRestorePassState} from '../../../../../store/actions';
import {ModalTypes} from '../../../../../enums';
import {ChangingStateTypes} from '../../../../user-panel/models/changing-state-types';
import {BehaviorSubject} from 'rxjs';
import {selectRestorePassError, selectRestorePassState} from '../../../../../store/selectors';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-forget-password-modal',
  templateUrl: './forget-password-modal.component.html',
  styleUrls: ['./forget-password-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgetPasswordModalComponent implements OnInit, OnDestroy {

  public prefix = '+48';
  public emailForm: FormGroup;
  public phoneForm: FormGroup;
  public restorePassState$: BehaviorSubject<ChangingStateTypes> = new BehaviorSubject<ChangingStateTypes>(ChangingStateTypes.Main);

  public error: string;
  public isPhoneSubmitted = false;
  public isEmailSubmitted = false;

  public get interfaceStates() {
    return ChangingStateTypes;
  }

  constructor(private dataService: SecureHttpClientService,
              private frmBuilder: FormBuilder,
              private store: Store<AppState>,
              private changeDetector: ChangeDetectorRef) {

    this.store.select(selectRestorePassState)
      .subscribe(data => {
        if (data && data >= 0) {
          this.restorePassState$.next(data);
        }
      });
    this.store.select(selectRestorePassError)
      .subscribe(errors => {
        if (errors && errors.msg) {
          this.checkForErrors(errors,
            (this.isEmailSubmitted ? this.emailForm.controls : this.phoneForm.controls),
            (this.isEmailSubmitted ? 'email' : 'phone')
          );
          this.changeDetector.markForCheck();
          this.changeDetector.detectChanges();
        }
      });
  }

  ngOnInit() {
    this.emailForm = this.frmBuilder.group({
      email: ['', [Validators.required, Validators.pattern(emailPattern)]],
    });
    this.phoneForm = this.frmBuilder.group({
      phone: ['', [Validators.required, Validators.minLength(9)]],
    });
  }

  ngOnDestroy() {
    this.store.dispatch(new SetRestorePassState(ChangingStateTypes.Main));
  }

  public login() {
    this.store.dispatch(new OpenModal(ModalTypes.LoginModal));
    }

  get email() {
    return this.emailForm.get('email');
  }

  get phone() {
    return this.phoneForm.get('phone');
  }

  public returnToLogin() {
    this.store.dispatch(new OpenModal(ModalTypes.LoginModal));
    this.store.dispatch(new SetRestorePassState(ChangingStateTypes.Main));
  }

  public restoreByEmail() {
    this.isEmailSubmitted = true;
    this.isPhoneSubmitted = false;
    this.store.dispatch(new RestorePassByEmail(this.email.value));
  }

  public restoreByPhone() {
    this.isEmailSubmitted = false;
    this.isPhoneSubmitted = true;
    this.store.dispatch(new RestorePassByPhone(this.phone.value));
  }

  public unsubmit() {
    this.isPhoneSubmitted = false;
    this.isEmailSubmitted = false;
  }

  private checkForErrors = (errors, controls, controlName) => {
    controls[controlName].setErrors({invalid: true, errMsg: errors.msg.toString().replace('_', ' ')});
  }

}
