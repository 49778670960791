import {ChangeDetectionStrategy, Component, HostListener, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../../services';
import {Router} from '@angular/router';
import {AppState} from '../../../../../store/state';
import {Store} from '@ngrx/store';
import {CloseModal, LogIn, OpenModal, SetAuthenticationError} from '../../../../../store/actions';
import {emailPattern} from '../../../../../utils';
import {ModalTypes} from '../../../../../enums';
import {selectAuthenticationError} from '../../../../../store/selectors';
import {BehaviorSubject, Observable} from 'rxjs';
import { selectCalculatorData } from 'src/app/store/selectors/info.selectors';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-calculator-info-modal',
  templateUrl: './calculator-info-modal.component.html',
  styleUrls: ['./calculator-info-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalculatorInfoModalComponent implements OnInit {

 // public amountThreshold = undefined;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
/*    this.store.select(selectCalculatorData)
      .pipe(
        tap()
      )
      .subscribe(data => {
        if (data && data.term && data.maxTerm && data.minTerm) {
          this.amountThreshold = data.amountThreshold;
        }
      });*/
  }


  public close = () => this.store.dispatch(new CloseModal());

}
