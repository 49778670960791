import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-information-clause-content',
  templateUrl: './information-clause-content.component.html',
  styleUrls: ['./information-clause-content.component.css']
})
export class InformationClauseContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
