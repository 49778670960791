import {ChangingStateTypes} from '../../modules/user-panel/models/changing-state-types';

export interface AuthState {
  isAuthenticated: boolean;
  id?: number | null;
  token: string | null;
  userName?: string | null;
  error?: any | null;
  registration?: any;
  restorePass?: any;
  authOrRegErrors?: any[];
}

export const initialAuthenticationState: AuthState = {
  isAuthenticated: false,
  token: null,
  restorePass: {
    state: ChangingStateTypes.Main
  }
};
