import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {selectCommonSuccessMessage, selectCommonSuccessRedirect} from '../../../../../store/selectors/info.selectors';
import {AppState} from '../../../../../store/state';
import {Store} from '@ngrx/store';
import {CloseModal, SetCommonSuccessMessage, SetCommonSuccessRedirect} from '../../../../../store/actions';
import { Router } from '@angular/router';

@Component({
  selector: 'app-common-success-modal',
  templateUrl: './common-success-modal.component.html',
  styleUrls: ['./common-success-modal.component.scss']
})
export class CommonSuccessModalComponent implements OnInit {
  public successText: Observable<string>;

  constructor(private store: Store<AppState>,
              private router: Router) { }

  ngOnInit() {
    this.successText = this.store.select(selectCommonSuccessMessage);
  }

  public close = () => {
    this.store.dispatch(new CloseModal());
    this.store.dispatch(new SetCommonSuccessMessage({successMessage: ''}));

    this.store.select(selectCommonSuccessRedirect).subscribe(data => {
      if (data !== undefined && data !== null && data !== '') {
        this.router.navigate([data]);       
        this.store.dispatch(new SetCommonSuccessRedirect({page: ''}));
      }
    });
  }
}
