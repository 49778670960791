import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-authority-content',
  templateUrl: './authority-content.component.html',
  styleUrls: ['./authority-content.component.css']
})
export class AuthorityContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
