import {Directive} from '@angular/core';
import {Validator, NG_VALIDATORS} from '@angular/forms';
import {emailPattern} from '../../../utils';

@Directive({
  selector: '[appEmail]',
  providers: [{provide: NG_VALIDATORS, useExisting: EmailDirective, multi: true}]
})
export class EmailDirective implements Validator {

  validate(c): { [key: string]: any } {
    if (c.value == null) {
      return null;
    }

    if (!emailPattern.test(c.value)) {
      return {pattern: true};
    }

    return null;
  }
}
