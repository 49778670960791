import {ChangeDetectionStrategy, Component, HostListener, Input, OnInit} from '@angular/core';
import {NavigationItem} from '../../../models';
import {AuthService} from '../../services';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../store/state';
import {selectContacts} from '../../store/selectors';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {

  @Input()
  public mainMenu: NavigationItem[];
  @Input()
  public footerMenu: NavigationItem[];
  @Input()
  public footerMenu2: NavigationItem[];

  public copyright: any;
  public currentYear = new Date().getFullYear();
  public contacts$: Observable<any>;
  public innerWidth: number;
  public shownMenu: '';

  @HostListener('window:resize', ['$event'])
  public onResize(event) {
    this.innerWidth = window.innerWidth;
  }


  constructor(private authService: AuthService,
              private store: Store<AppState>) {
    this.contacts$ = this.store.pipe(
      select(selectContacts)
    );
  }

  get isAuthorized() {
    return this.authService.isAuthorized$;
  }

  ngOnInit() {
  }

  public toggleMenu(e, menu) {
    e.preventDefault();
    if (this.innerWidth > 1024) {
      return;
    }
    if (this.shownMenu === menu) {
      this.shownMenu = '';
    } else {
      this.shownMenu = menu;
    }
  }

  public showMenu(menuName) {
    return this.shownMenu === menuName;
  }

}
