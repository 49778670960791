import {Action} from '@ngrx/store';
import {NavigationItem} from '../../../models';

export enum NavigationActionTypes {
  LoadAllNavigations = '[Navigation] Load All Navigation',
  LoadNavigation = '[Navigation] Load Navigation',
  LoadUserNavigation = '[Navigation] Load User Navigation',
  LoadFooterNavigation = '[Navigation] Load Footer Navigation',
  LoadSecondaryFooterNavigation = '[Navigation] Load Secondary Footer Navigation',
  SetNavigation = '[Navigation] Set Navigation',
  SetUserNavigation = '[Navigation] Set User Navigation',
  SetFooterNavigation = '[Navigation] Set Footer Navigation',
  SetFooterSecondaryNavigation = '[Navigation] Set Secondary Footer Navigation',
  SetNavigationError = '[Navigation] Set Navigation Error',
}

export class LoadAllNavigations {
  readonly type = NavigationActionTypes.LoadAllNavigations;
}

export class LoadNavigation implements Action {
  readonly type = NavigationActionTypes.LoadNavigation;
}

export class LoadUserNavigation implements Action {
  readonly type = NavigationActionTypes.LoadUserNavigation;
}

export class LoadFooterNavigation implements Action {
  readonly type = NavigationActionTypes.LoadFooterNavigation;
}

export class LoadFooterSecondaryNavigation implements Action {
  readonly type = NavigationActionTypes.LoadSecondaryFooterNavigation;
}

export class SetNavigationError implements Action {
  readonly type = NavigationActionTypes.SetNavigationError;

  constructor(readonly payload: { error: string }) {
  }
}

export class SetNavigation implements Action {
  readonly type = NavigationActionTypes.SetNavigation;

  constructor(readonly payload: { navigationData: NavigationItem[] }) {
  }
}

export class SetUserNavigation implements Action {
  readonly type = NavigationActionTypes.SetUserNavigation;

  constructor(readonly payload: { userNavigationData: NavigationItem[] }) {
  }
}

export class SetFooterNavigation implements Action {
  readonly type = NavigationActionTypes.SetFooterNavigation;

  constructor(readonly payload: { footerNavigationData: NavigationItem[] }) {
  }
}

export class SetFooterSecondaryNavigation implements Action {
  readonly type = NavigationActionTypes.SetFooterSecondaryNavigation;

  constructor(readonly payload: { footerSecondaryNavigationData: NavigationItem[] }) {
  }
}

export type NavigationActions = LoadAllNavigations |
  LoadNavigation |
  LoadUserNavigation |
  LoadFooterNavigation |
  LoadFooterSecondaryNavigation |
  SetNavigationError |
  SetNavigation |
  SetUserNavigation |
  SetFooterNavigation |
  SetFooterSecondaryNavigation;
