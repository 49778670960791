import {ChangeDetectionStrategy, Component, HostListener, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../../services';
import {Router} from '@angular/router';
import {AppState} from '../../../../../store/state';
import {Store} from '@ngrx/store';
import {CloseModal, LogIn, OpenModal, SetAuthenticationError} from '../../../../../store/actions';
import {emailPattern} from '../../../../../utils';
import {ModalTypes} from '../../../../../enums';
import {selectAuthenticationError} from '../../../../../store/selectors';
import {BehaviorSubject, Observable} from 'rxjs';

@Component({
  selector: 'app-free-loan-read-more-modal',
  templateUrl: './free-loan-read-more.component.html',
  styleUrls: ['./free-loan-read-more.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FreeLoanReadMoreModalComponent implements OnInit {


  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {

  }


  public close = () => this.store.dispatch(new CloseModal());

}
