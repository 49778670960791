import {NavigationItem} from '../../../models';

export interface NavState {
  navItems?: NavigationItem[] | null;
  userNavItems?: NavigationItem[] | null;
  footerNavItems?: NavigationItem[] | null;
  footerSecondaryNavItems?: NavigationItem[] | null;
  error?: string | null;
}

export const initialNavigationState: NavState = {
  navItems: [],
  userNavItems: [],
  footerNavItems: [],
  footerSecondaryNavItems: []
};
