import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import {navigationReducer} from './navigation.reducer';
import {AppState} from '../state';
import {authenticationReducer} from './authentication.reducer';
import {modalsReducer} from './modals.reducer';
import {infoReducer} from './info.reducer';
import {userReducer} from './user.reducer';

export const reducers: ActionReducerMap<AppState> = {
  navigation: navigationReducer,
  auth: authenticationReducer,
  modals: modalsReducer,
  info: infoReducer,
  user: userReducer,
};


export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
