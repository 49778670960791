import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {AppState} from '../store/state';
import {select, Store} from '@ngrx/store';
import {selectAuthenticationStatus} from '../store/selectors';
import {map, take} from 'rxjs/operators';

@Injectable()
export class NoAuthGuard implements CanActivate {
  constructor(private store: Store<AppState>,
              private router: Router) {
  }

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(selectAuthenticationStatus),
      map(isAuth => {
        if (!isAuth) {
          this.router.navigate(['/']);
          return false;
        }

        return true;
      }),
      take(1)
    );
  }
}
