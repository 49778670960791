import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-electronic-agreement-content',
  templateUrl: './electronic-agreement-content.component.html',
  styleUrls: ['./electronic-agreement-content.component.css']
})
export class ElectronicAgreementContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
