import { Component, OnInit} from '@angular/core';
import {AppState} from '../../../../../store/state';
import {Store} from '@ngrx/store';
import {CloseModal} from '../../../../../store/actions';
import { ClientLoanStateTypes } from 'src/app/modules/user-panel/models/client-loan-types';
import { selectLoanCurrentInfo, selectUser } from 'src/app/store/selectors';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation-modal.component.html',
  styleUrls: ['./documentation-modal.component.css']
})
export class DocumentationModalComponent implements OnInit {

  public loan: any;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
    this.loadData();
  }

  private loadData() {
    combineLatest([
      this.store.select(selectUser),
      this.store.select(selectLoanCurrentInfo)
    ]).pipe(
      // get only non empty values
      filter(values => Object.values(values).every(_ => _)),
      map((responses) => ({
          user: responses[0],
          loanCurrentInfo: responses[1]
        })
      )
    ).subscribe(data => {
      if (data) {
        this.loan = {
          ...data.loanCurrentInfo,
          isWaitingDecision: (data.loanCurrentInfo.status === ClientLoanStateTypes.Requested ||
            data.loanCurrentInfo.status === ClientLoanStateTypes.Approved && data.loanCurrentInfo)
        };
      }
    });
  }


  public close() {
    this.store.dispatch(new CloseModal());
  }

}

