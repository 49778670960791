import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../store/state';
import {CloseModal, SetCommonErrorMessage} from '../../../../../store/actions';
import {Observable} from 'rxjs';
import {selectCommonErrorMessage} from '../../../../../store/selectors/info.selectors';

@Component({
  selector: 'app-common-error-modal',
  templateUrl: './common-error-modal.component.html',
  styleUrls: ['./common-error-modal.component.scss']
})
export class CommonErrorModalComponent implements OnInit {

  public errText: Observable<string>;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
    this.errText = this.store.select(selectCommonErrorMessage);
  }

  public close = () => {
    this.store.dispatch(new CloseModal());
    this.store.dispatch(new SetCommonErrorMessage({errMessage: ''}));
  }

}
