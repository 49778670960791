import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

const mockBanksList = [
  {
    name: 'PKO',
    code: 'Bank102',
    url: 'www.pkobp.pl',
    account: '51 1020 1332 0000 1102 0859 8957',
    defaultBank: false
  },
  {
    name: 'Santander',
    code: 'Bank109',
    url: 'www.bzwbk.pl',
    account: '47 1090 2590 0000 0001 2181 1363',
    defaultBank: false
  },
  {
    name: 'mBank',
    code: 'Bank114',
    url: 'www.brebank.pl',
    account: '11 1140 1775 0000 2541 7200 1001',
    defaultBank: false
  },
  {
    name: 'Pekao',
    code: 'Bank124',
    url: 'www.pekao.com.pl',
    account: '69 1240 5891 1111 0010 5292 9343',
    defaultBank: false
  },
  {
    name: 'ALIOR',
    code: 'Bank249',
    url: 'www.aliorbank.pl',
    account: '52 2490 0005 0000 4530 8748 1021',
    defaultBank: false
  },
  {
    name: 'Pocztowy',
    code: 'Bank132',
    url: 'www.pocztowy.pl',
    account: '13 1320 0019 1649 1090 2000 0001',
    defaultBank: false
  }
];

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) {
  }

  private apiUrl = environment.apiUrl;

  public get<T>(url, data?: any, headers?: any): Observable<any> {
    if (url === 'date/now') {
      return of(new Date().toString());
    }

    const requestOptions: any = {};
    if (data) {
      requestOptions.params = data;
    }
    if (headers) {
      requestOptions.headers = headers;
    }

    return this.http.get<T>(this.apiUrl + this.getUrl(url), requestOptions);
  }

  public post<T>(url, data: any, headers?: any): Observable<any> {
    const requestOptions: any = {
      data,
    };
    if (headers) {
      requestOptions.headers = headers;
    }
    return this.http.post<T>(this.apiUrl + this.getUrl(url), requestOptions.data, {headers: requestOptions.headers});
  }

  public put<T>(url, data: any, headers?: any): Observable<any> {
    const requestOptions: any = {
      data,
    };
    if (headers) {
      requestOptions.headers = headers;
    }
    return this.http.put<T>(this.apiUrl + this.getUrl(url), requestOptions.data, {headers: requestOptions.headers});
  }

  public delete<T>(url, data = null): Observable<any> {
    return this.http.delete(this.apiUrl + this.getUrl(url), data || {});
  }

  public getMyIp(): Promise<string> {
    return this.http.get('https://api.ipify.org?format=json')
      .pipe(
          map((resp: any) => resp && resp.ip)
        ).toPromise();
  }

  private getUrl = (url): string => url.charAt(0) === '/' ? url.substring(1, url.length) : url;
}
