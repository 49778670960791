export enum ModalTypes {
  None = 0,
  LoginModal = 1,
  ForgetPassModal = 2,
  DocumentationModal = 3,
  LoanRequest = 4,
  CommonErrorModal = 5,
  CommonSuccessModal = 6,
  RegErrorModal = 7,
  Formular = 8,
  FreeLoanReadMore = 9,
  LoanCosts = 10,
  DiscountRegulations = 11,
  Kotmatic = 12,
  Content = 13,
  ContentMobile = 14,
  CalculatorInfo = 15,
  Feedback = 16,
}
