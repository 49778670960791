import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.css']
})
export class ValidationComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() formControlName: string;
  @Input() classes: string;
  constructor() { }

  ngOnInit() {
  }

}
