import {Component, OnInit} from '@angular/core';
import {AppState} from './store/state';
import {select, Store} from '@ngrx/store';
import {LoadAllNavigations, LoadConditions, LoadContacts, LoadCurrentDate, LogOut, SetAuthenticationStatus, OpenModal} from './store/actions';
import {Observable} from 'rxjs';
import {NavigationItem} from '../models';
import {
  selectCurrentModalType,
  selectFooterNavigation,
  selectFooterSecondaryNavigation,
  selectNavigation,
  selectUserNavigation
} from './store/selectors';
import {ModalTypes} from './enums';
import {AuthService, ApiService, AffiliateService} from './services';
import {NavigationEnd, Router} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { globalVariables } from 'src/environments/globalVariables';
import { NgxCookiebotService } from '@halloverden/ngx-cookiebot';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public navItems$: Observable<NavigationItem[]>;
  public userNavItems$: Observable<NavigationItem[]>;
  public footerNavItems$: Observable<NavigationItem[]>;
  public footerSecondaryNavItems$: Observable<NavigationItem[]>;
  public modalTypes = ModalTypes;
  public selectedModal$: Observable<number>;

  public mainPage:boolean = true;

  constructor(private store: Store<AppState>,
              private authService: AuthService,
              private router: Router,
              private cookieService: CookieService,
              private apiService: ApiService,
              private affService: AffiliateService,
              private cookieBotService: NgxCookiebotService) {

    /*
    // unregister SW on page if exists
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (const registration of registrations) {
          registration.unregister();
        }
      });
    }*/

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);

      if (evt.url.includes('logowanie') || evt.url.includes('wejscie-do-profilu')) {
        this.store.dispatch(new OpenModal(ModalTypes.LoginModal));
      }
    });
    this.authService.checkAuthState();
    this.affService.subscribe();
  }

  ngOnInit(): void {
    if (this.authService.getAuthToken()) {
      if (this.authService.getExpiredToken() &&
      (new Date()).getTime() < (Number(this.authService.getExpiredToken()))) {
        this.authService.setExpiredToken((String)((new Date()).getTime() + environment.tokenExpireTime));
      } else {
        this.store.dispatch(new LogOut());
        this.store.dispatch(new SetAuthenticationStatus({isAuthenticated: false, token: null}));
      }
    }

    this.store.dispatch(new LoadAllNavigations());
    this.store.dispatch(new LoadContacts());
    this.store.dispatch(new LoadCurrentDate());
    if (!this.authService.getAuthToken()) {
      this.store.dispatch(new LoadConditions());
    }

    this.navItems$ = this.store.pipe(select(selectNavigation));
    this.userNavItems$ = this.store.pipe(select(selectUserNavigation));
    this.selectedModal$ = this.store.pipe(select(selectCurrentModalType));
    this.footerNavItems$ = this.store.pipe(select(selectFooterNavigation));
    this.footerSecondaryNavItems$ = this.store.pipe(select(selectFooterSecondaryNavigation));

    // tslint:disable-next-line:max-line-length
    if (document.referrer != null && document.referrer.length > 0 && document.referrer.indexOf(location.protocol + '//' + location.host) < 0) {
      if (navigator.cookieEnabled) {
        this.cookieService.set('FinSasClientReferrer', document.referrer);
      } else {
        globalVariables.FinSasClientReferrer = document.referrer;
      }
    }

    // tslint:disable-next-line:max-line-length
    if ((document.referrer == null || document.referrer.indexOf(location.protocol + '//' + location.host) < 0) && window.location.search.length > 0) {
      this.apiService.post(`/eb7133f2-963a-4b01-aa45-c1fac90d03e6/GetAffiliate`, { url: window.location.href })
      .subscribe(
        (val) => {
            if (val && val.success === true && val.networkID !== 0) {
              if (navigator.cookieEnabled) {
                this.cookieService.set('FinSasAff', window.location.href, val.lifetime > 0 ?
                  val.lifetime / 86400 : 30);
              } else {
                globalVariables.FinSasAff = window.location.href;
              }
            }
        });
  }

  this.cookieBotService.onAcceptCallback$.subscribe(() => {
    console.log(this.cookieBotService.cookiebot.consent)
  });
}

ngAfterViewInit(): void {
  this.loadScript("https://consent.cookiebot.com/uc.js", new Map([
    ['id', 'Cookiebot'],
    ['data-cbid', '35146ead-5fb7-4b25-b29b-cc13dadea7d6'],
    ['data-blockingmode', 'auto'],
  ]));
  this.loadScript('/assets/vendor/jquery.appear/jquery.appear.min.js');
  this.loadScript('/assets/vendor/jquery.easing/jquery.easing.min.js');
  this.loadScript('/assets/vendor/jquery.cookie/jquery.cookie.min.js');
  this.loadScript('/assets/vendor/bootstrap/js/bootstrap.bundle.min.js');
  this.loadScript('/assets/vendor/jquery.validation/jquery.validate.min.js');
  this.loadScript('/assets/vendor/jquery.easy-pie-chart/jquery.easypiechart.min.js');
  this.loadScript('/assets/vendor/jquery.gmap/jquery.gmap.min.js');
  this.loadScript('/assets/vendor/lazysizes/lazysizes.min.js');
  this.loadScript('/assets/vendor/isotope/jquery.isotope.min.js');
  this.loadScript('/assets/vendor/owl.carousel/owl.carousel.min.js');
  this.loadScript('/assets/vendor/magnific-popup/jquery.magnific-popup.min.js');
  this.loadScript('/assets/vendor/vide/jquery.vide.min.js');
  this.loadScript('/assets/vendor/vivus/vivus.min.js');
  //this.loadScript('/assets/vendor/particles/particles.min.js');
  //this.loadScript('/assets/js/demos/demo-business-consulting-4.js');

  this.loadScript('/assets/js/theme.js');
  this.loadScript('/assets/js/theme.init.js');

  this.loadScript('/assets/js/custom.js');
  this.loadScript('/assets/js/examples/examples.portfolio.js');
  this.loadScript('/assets/js/examples/examples.lightboxes.js');
}

ngAfterViewChecked(): void {
  this.mainPage = (this.router.url === '/'
    || this.router.url === '/logowanie'
    || this.router.url === '/powracajacy'
    || this.router.url === '/wejscie-do-profilu');
}

public loadScript(url: string, params: Map<string, string> = null) {
  if (document.querySelectorAll(`script[src="${url}"]`).length > 0) {
    return;
  }
  const body = <HTMLDivElement> document.body;
  const script = document.createElement('script');
  script.innerHTML = '';
  script.src = url;
  script.async = false;
  script.defer = true;
  if (params) {
    params.forEach((value: string, key: string) => {
      script.setAttribute(key, value)
    });
  }
  body.appendChild(script);
}
}
