import {Component, OnInit, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-manual-validation',
  templateUrl: './manual-validation.component.html',
  styleUrls: ['./manual-validation.component.css']
})
export class ManualValidationComponent implements OnInit {

  @Input()
  public form: FormGroup;
  @Input()
  public controlName: string;
  @Input()
  public isSubmitted: boolean;

  constructor() {
  }

  ngOnInit() {
  }


}
