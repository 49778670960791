import {ChangeDetectionStrategy, Component, HostListener, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../../services';
import {Router} from '@angular/router';
import {AppState} from '../../../../../store/state';
import {Store} from '@ngrx/store';
import {CloseModal, LogIn, OpenModal, SetAuthenticationError} from '../../../../../store/actions';
import {emailPattern} from '../../../../../utils';
import {ModalTypes} from '../../../../../enums';
import {selectAuthenticationError} from '../../../../../store/selectors';
import {BehaviorSubject, Observable} from 'rxjs';
import { parentPageContent, selectPageContent } from 'src/app/store/selectors/info.selectors';
import { SetContentPage } from 'src/app/store/actions/info.actions';

@Component({
  selector: 'app-content-modal-mobile',
  templateUrl: './content-mobile-modal.component.html',
  styleUrls: ['./content-mobile-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentMobileModalComponent implements OnInit {

  public page: string;

  constructor(private auth: AuthService,
              private router: Router,
              private frmBuilder: FormBuilder,
              private store: Store<AppState>) {
  }

  ngOnInit() {
    this.store.select(selectPageContent)
    .subscribe(data => {
      this.page = data;
    });
  }

  public close = () => {
      this.store.dispatch(new CloseModal());
  }
}
