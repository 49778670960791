import {Action} from '@ngrx/store';
import {ChangingStateTypes} from '../../modules/user-panel/models/changing-state-types';
import {RefinanceRequestType} from '../../modules/user-panel/models/refinance-types';
import {ExtensionLoanTypes} from '../../enums/ExtensionLoanTypes';
import {CompleteAffiliateRegDataModel} from '../../modules/static-pages/models/RegDataModel';


export enum UserActionTypes {
  LoadUserData = '[User] Load User Data',
  LoadUserDataSuccess = '[User] Load User Data success',
  SetUserData = '[User] Set User',
  LoadLoanSchedule = '[User] Load user loan schedule',
  SetLoanSchedule = '[User] Set user loan schedule',
  LoadLoanState = '[User] Load user loan state',
  SetLoanState = '[User] Set user loan state',
  LoadRefinanceOptions = '[User] Load user refinance options',
  SetRefinanceOptions = '[User] Set user refinance options',
  LoadLoanCurrentInfo = '[User] Load loan current info',
  SetLoanCurrentInfo = '[User] Set loan current info',
  LoadLoanCurrentInfoSuccess = '[User] Load loan current info success',
  LoadLoanHistory = '[User] load loan history',
  SetLoanHistory = '[User] set loan history',
  SetLoanHistoryLoadedStatus = '[User] set loan history loaded status',
  BeforeRequestLoan = '[User] Before Loan Request',
  BeforeRequestLoanSuccess = '[User] Before Loan Request success',
  CreateLoanRequest = '[User] Create Loan Request',
  CancelLoanRequest = '[User] Cancel Loan Request',
  CreateLoanRefinanceRequest = '[User] Create Loan Refincanc Request',
  LoadIncomeDocuments = '[Info] Load Income Documents',
  RefinanceRequestSuccess = '[User] Create Refinance Request success',
  RefinanceRequestError = '[User] Create Refinance Request error',
  LoanRequestStatusClear = '[User] Create Loan Request clear',
  LoanRequestSuccess = '[User] Create Loan Request success',
  DeleteDocument = '[User] Delete uploaded document',
  LoanRequestError = '[User] Create Loan Request error',
  DeleteLoanRequest = '[User] Delete Loan request',
  DeleteLoanRequestSuccess = '[User] Delete Loan request success',
  OpenUserPopup = '[User] Open editor popup',
  CloseUserPopup = '[User] Close editor popup',

  SetCurrentRefinanceOptions = '[User] SetCurrentRefinanceOptionIndex',

  LoadPersonalConditions = '[User] Load personal conditions',
  SetPersonalConditions = '[User] Set personal conditions',
  LoadPersonalConditionsError = '[User] Load pers conditions error',

  LoadPersonalRefinanceCities = '[User] Load personal refinance cities',
  SetPersonalRefinanceCities = '[User] Set personal refinance cities',
  LoadPersonalRefinanceCitiesError = '[User] Load personal refinance cities error',

  ToggleMarketingSubscription = '[User] toggle marketing subscription',
  MarketingSubscriptionError = '[User] marketing subscription error',
  MarketingSubscriptionSuccess = '[User] marketing subscription success',

  ChangeEmail = '[User] Change user email',
  ChangeEmailSuccess = '[User] Change user email success',
  ChangeEmailError = '[User] Change user email error',
  ConfirmEmailChange = '[User] Confirm email changing',

  ChangePhone = '[User] Change user phone',
  ChangePhoneSuccess = '[User] Change user phone success',
  ChangePhoneError = '[User] Change user phone error',
  ConfirmPhoneChange = '[User] Confirm Phone changing',

  AffiliateCompleteRegistration = '[User] Complete affiliate registration',
  AffiliateCompleteRegistrationSuccess = '[User] Complete affiliate registration success',

  LoadIncomeDocumentsSuccess =  '[User] Load Income Documents Success',

  LoanSmsVerification = '[User] Verify loan request by sms',
  SetLoanSmsVerificationStatus = '[User] Set loan request verification status',
  LoanSmsVerificationSuccess = '[User] Verify loan request by sms Success',
  LoanSmsVerificationError = '[User] Verify phone by sms Error',
  SetLoanVerificationFormSubmittedStatus = '[User] Set submitted status',
  LoanVerificationRequested = '[User] Loan Verification Requested',

  SetLoanConfirmationSmsRequestedStatus = '[User] Set loan confirmation sms requested status',

  RequestSmsCode = '[User] Request sms code',
  RequestSmsCodeSuccess = '[User] Request sms code success',
  RequestSmsCodeError = '[User] Request sms code error',

  CreatePassword = '[User] Create user password',
  ChangePassword = '[User] Change user password',
  ChangePasswordSuccess = '[User] Change user phone success',

  RequestVerificationCodeAgain = '[User] Request Verification Code Again',
  SetChangingState = '[User] Set changing state',
  LoadLoanError = '[User] Loading loan error',
  CleanUser = '[User] Clean User',

  LoadUserNotifications = '[User] load notifications',
  SetUserNotifications = '[User] set notifications',

  CallConfirmLoanNotification = '[User] Call confirm loan notification',

  ChooseExtensionLoanType = '[User] Choose extended loan type',
  ChangeUserPassword = 'ChangeUserPassword',
  ConfirmIncomeDocuments = "ConfirmIncomeDocuments",

  SubmitFeedback = '[User] Submit feedback',
}

export class ChooseExtensionLoanType implements Action {
  readonly type = UserActionTypes.ChooseExtensionLoanType;

  constructor(readonly extensionType: ExtensionLoanTypes) {
  }
}

export class LoadUserData implements Action {
  readonly type = UserActionTypes.LoadUserData;

  constructor(readonly payload: { token: string }) {
  }
}

export class LoadUserDataSuccess implements Action {
  readonly type = UserActionTypes.LoadUserDataSuccess;
}

export class SetUserData implements Action {
  readonly type = UserActionTypes.SetUserData;

  constructor(readonly payload: { userData: any }) {
  }
}

export class LoadLoanSchedule implements Action {
  readonly type = UserActionTypes.LoadLoanSchedule;
}

export class SetLoanSchedule implements Action {
  readonly type = UserActionTypes.SetLoanSchedule;

  constructor(readonly payload: { loanSchedule: any[] }) {
  }
}

export class LoadLoanState implements Action {
  readonly type = UserActionTypes.LoadLoanState;
}

export class SetLoanState implements Action {
  readonly type = UserActionTypes.SetLoanState;

  constructor(readonly payload: { loanState: any }) {
  }
}

export class LoadRefinanceOptions implements Action {
  readonly type = UserActionTypes.LoadRefinanceOptions;

  constructor(readonly payload?: {
      loanNumber?: number,
      amount?: string,
      bankListType?: number
  }) {
  }
}

export class SetRefinanceOptions implements Action {
  readonly type = UserActionTypes.SetRefinanceOptions;

  constructor(readonly payload: { refinanceOptions: any }) {
  }
}

export class LoadLoanCurrentInfo implements Action {
  readonly type = UserActionTypes.LoadLoanCurrentInfo;

  constructor(readonly payload?: {withPersonalOptions: boolean}) {
  }
}

export class LoadLoanCurrentInfoSuccess implements Action {
  readonly type = UserActionTypes.LoadLoanCurrentInfoSuccess;
}

export class SetLoanCurrentInfo implements Action {
  readonly type = UserActionTypes.SetLoanCurrentInfo;

  constructor(readonly payload: { loanCurrentInfo: any }) {
  }
}

export class LoadPersonalConditions implements Action {
  readonly type = UserActionTypes.LoadPersonalConditions;
}

export class SetPersonalConditions implements Action {
  readonly type = UserActionTypes.SetPersonalConditions;

  constructor(readonly payload: {conditions: any[]}) {
  }
}

export class LoadPersonalConditionsError implements Action {
  readonly type = UserActionTypes.LoadPersonalConditionsError;

  constructor(readonly payload: {errMessage: any}) {
  }
}

export class LoadPersonalRefinanceCities implements Action {
  readonly type = UserActionTypes.LoadPersonalRefinanceCities;
}

export class SetPersonalRefinanceCities implements Action {
  readonly type = UserActionTypes.SetPersonalRefinanceCities;

  constructor(readonly payload: {cities: any[]}) {
  }
}

export class LoadPersonalRefinanceCitiesError implements Action {
  readonly type = UserActionTypes.LoadPersonalRefinanceCitiesError;

  constructor(readonly payload: {errMessage: any}) {
  }
}

export class CreateLoanRefinanceRequest implements Action {
  readonly type = UserActionTypes.CreateLoanRefinanceRequest;

  constructor(readonly payload: {requestData: any}) {
  }
}


export class CancelLoanRequest implements Action {
  readonly type = UserActionTypes.CancelLoanRequest;

  constructor(readonly payload: {requestData: any}) {
  }
}

export class CreateLoanRequest implements Action {
  readonly type = UserActionTypes.CreateLoanRequest;

  constructor(readonly payload: {requestData: any}) {
  }
}

export class LoanRequestStatusClear implements Action {
  readonly type = UserActionTypes.LoanRequestStatusClear;

  constructor() {
  }
}

export class LoanRequestSuccess implements Action {
  readonly type = UserActionTypes.LoanRequestSuccess;

  constructor(readonly payload: {newId: number}) {
  }
}

export class DeleteDocument implements Action {
  readonly type = UserActionTypes.DeleteDocument;

  constructor(readonly payload: {accessToken: string, documentId: number}) {
  }
}

export class LoadIncomeDocuments implements Action {
  readonly type = UserActionTypes.LoadIncomeDocuments;

  constructor() {}
}


export class LoanRequestError implements Action {
   readonly type = UserActionTypes.LoanRequestError;

   constructor(readonly payload: {errMessage: any}) {
   }
}

export class RefinanceRequestSuccess implements Action {
  readonly type = UserActionTypes.RefinanceRequestSuccess;
}


export class RefinanceRequestError implements Action {
  readonly type = UserActionTypes.RefinanceRequestError;

  constructor(readonly payload: {errMessage: any}) {
  }
}

export class DeleteLoanRequest implements Action {
  readonly type = UserActionTypes.DeleteLoanRequest;
}

export class DeleteLoanRequestSuccess implements Action {
  readonly type = UserActionTypes.DeleteLoanRequestSuccess;
}

export class OpenEditPopup implements Action {
  readonly type = UserActionTypes.OpenUserPopup;

  constructor(readonly popupType: number ) {
  }
}

export class CloseUserPopup implements Action {
  readonly type = UserActionTypes.CloseUserPopup;
}

export class SetCurrentRefinanceOptions implements Action {
  readonly type = UserActionTypes.SetCurrentRefinanceOptions;

  constructor(readonly payload: {
    term: any,
    fee?: any,
    type: RefinanceRequestType
  }) {
  }
}

export class ChangeEmail implements Action {
  readonly type = UserActionTypes.ChangeEmail;

  constructor(readonly payload: {newEmail: string}) {
  }
}

export class LoadIncomeDocumentsSuccess implements Action {
  readonly type = UserActionTypes.LoadIncomeDocumentsSuccess;

  constructor(readonly payload: {documents: any}) {
  }
}

export class ChangeEmailSuccess implements Action {
  readonly type = UserActionTypes.ChangeEmailSuccess;

  constructor(readonly payload: {state: ChangingStateTypes, newEmail?: string}) {
  }
}



export class ChangeEmailError implements Action {
  readonly type = UserActionTypes.ChangeEmailError;

  constructor(readonly payload: {errMessage: any, state: ChangingStateTypes}) {
  }
}

export class ConfirmEmailChange implements Action {
  readonly type = UserActionTypes.ConfirmEmailChange;

  constructor(readonly payload: {newEmail: string, code: string}) {
  }
}

export class ChangePhone implements Action {
  readonly type = UserActionTypes.ChangePhone;

  constructor(readonly payload: {newPhone: string}) {
  }
}

export class ChangePhoneSuccess implements Action {
  readonly type = UserActionTypes.ChangePhoneSuccess;

  constructor(readonly payload: {state: ChangingStateTypes, newPhone?: string}) {
  }
}

export class ChangePhoneError implements Action {
  readonly type = UserActionTypes.ChangePhoneError;

  constructor(readonly payload: {errMessage: any, state: ChangingStateTypes}) {
  }
}

export class ConfirmPhoneChange implements Action {
  readonly type = UserActionTypes.ConfirmPhoneChange;

  constructor(readonly payload: {newPhone: string, code: string}) {
  }
}

export class SetChangingState implements Action {
  readonly type = UserActionTypes.SetChangingState;

  constructor(readonly state: ChangingStateTypes) {
  }
}

export class RequestVerificationCodeAgain implements Action {
  readonly type = UserActionTypes.RequestVerificationCodeAgain;
}


export class BeforeRequestLoan implements Action {
  readonly type = UserActionTypes.BeforeRequestLoan;

  constructor(readonly payload: {requestData: any}) {
  }
}

export class BeforeRequestLoanSuccess implements Action {
  readonly type = UserActionTypes.BeforeRequestLoanSuccess;

  constructor(readonly payload: {updateIncome?: boolean, updateExpenses?: boolean, confirmedBankAccount?: boolean, showDiscountRegulations?:boolean}) {
  }

}



export class ToggleMarketingSubscription implements Action {
  readonly type = UserActionTypes.ToggleMarketingSubscription;

  constructor(readonly payload: {turnEmailOn: boolean, turnSmsOn: boolean, turnPartnerOn: boolean}) {
  }
}

export class MarketingSubscriptionSuccess implements Action {
  readonly type = UserActionTypes.MarketingSubscriptionSuccess;

  constructor(readonly payload: {turnEmailOn: boolean, turnSmsOn: boolean, turnPartnerOn: boolean}) {
  }
}

export class MarketingSubscriptionError implements Action {
  readonly type = UserActionTypes.MarketingSubscriptionError;

  constructor(readonly payload: {errMessage: string}) {
  }
}

export class ChangeUserPassword implements Action {
  readonly type = UserActionTypes.ChangePassword;

  constructor(readonly payload: {oldPass: string, newPass: string, confirmPass: string}) {
  }
}

export class CreateUserPassword implements Action {
  readonly type = UserActionTypes.CreatePassword;

  constructor(readonly payload: {oldPass: string, newPass: string, confirmPass: string}) {
  }
}

export class ChangeUserPasswordSuccess implements Action {
  readonly type = UserActionTypes.ChangePasswordSuccess;

  constructor(readonly payload?: any) {
  }
}

export class LoadLoanHistory implements Action {
  readonly type = UserActionTypes.LoadLoanHistory;
}

export class SetLoanHistory implements Action {
  readonly type = UserActionTypes.SetLoanHistory;

  constructor(readonly payload: {loanHistory: any[]}) {
  }
}
export class SetLoanHistoryLoadedStatus implements Action {
  readonly type = UserActionTypes.SetLoanHistoryLoadedStatus;

  constructor(readonly loaded: boolean) {
  }
}

export class LoadLoanError implements Action {
  readonly type = UserActionTypes.LoadLoanError;

  constructor(readonly payload: { errMessage: any }) {
  }
}

export class AffiliateCompleteRegistration implements Action {
  readonly type = UserActionTypes.AffiliateCompleteRegistration;

  constructor(readonly payload: {completeRegData: CompleteAffiliateRegDataModel}) {
  }
}

export class AffiliateCompleteRegistrationSuccess implements Action {
  readonly type = UserActionTypes.AffiliateCompleteRegistrationSuccess;
}

export class LoanSmsVerification implements Action {
  readonly type = UserActionTypes.LoanSmsVerification;

  constructor(readonly payload: {
    verificationCode: string,
    clientId: string | number,
    loanRequestId: string | number
  }) {
  }
}

export class LoanVerificationRequested {
  readonly type = UserActionTypes.LoanVerificationRequested;

  constructor(readonly payload: boolean) {
  }
}

export class SetLoanSmsVerificationStatus implements Action {
  readonly type = UserActionTypes.SetLoanSmsVerificationStatus;

  constructor(readonly payload?: {status: boolean}) {
  }
}

export class SetLoanVerificationFormSubmittedStatus implements Action {
  readonly type = UserActionTypes.SetLoanVerificationFormSubmittedStatus;

  constructor(readonly isSubmitted: boolean) {
  }
}

export class LoanSmsVerificationSuccess implements Action {
  readonly type = UserActionTypes.LoanSmsVerificationSuccess;
}

export class LoanSmsVerificationError implements Action {
  readonly type = UserActionTypes.LoanSmsVerificationError;

  constructor(readonly payload: {errMessage: any}) {
  }
}

export class RequestSmsCode implements Action {
  readonly type = UserActionTypes.RequestSmsCode;

  constructor(readonly payload: {clientId: number | string, loanRequestId: number | string}) {
  }
}

export class RequestSmsCodeSuccess implements Action {
  readonly type = UserActionTypes.RequestSmsCodeSuccess;
}

export class RequestSmsError implements Action {
  readonly type = UserActionTypes.RequestSmsCodeError;

  constructor(readonly payload: {errMessage: any}) {
  }
}

export class CleanUser implements Action {
  readonly type = UserActionTypes.CleanUser;
}

export class LoadUserNotifications implements Action {
  readonly type = UserActionTypes.LoadUserNotifications;
}

export class SetUserNotifications implements Action {
  readonly type = UserActionTypes.SetUserNotifications;

  constructor(readonly payload: {notifications: any[]}) {
  }
}

export class CallConfirmLoanNotification implements Action {
  readonly type = UserActionTypes.CallConfirmLoanNotification;
}

export class SetLoanConfirmationSmsRequestedStatus implements Action {
  readonly type = UserActionTypes.SetLoanConfirmationSmsRequestedStatus;
  constructor(readonly status: boolean) {
  }
}

export class SubmitFeedback implements Action {
  readonly type = UserActionTypes.SubmitFeedback;

  constructor(readonly payload: {name: string, email: string, feedback: string}) {
  }
}

export type UserActions = LoadUserData |
  LoadUserDataSuccess |
  SetUserData |
  LoadLoanSchedule |
  SetLoanSchedule |
  LoadLoanState |
  SetLoanState |
  LoadRefinanceOptions |
  SetRefinanceOptions |
  LoadLoanCurrentInfo |
  LoadLoanCurrentInfoSuccess |
  SetLoanCurrentInfo |
  LoadPersonalConditions |
  SetPersonalConditions |
  LoadPersonalConditionsError |
  LoadPersonalRefinanceCities |
  SetPersonalRefinanceCities |
  LoadPersonalRefinanceCitiesError |
  CreateLoanRequest |
  CancelLoanRequest |
  CreateLoanRefinanceRequest |
  LoanRequestSuccess |
  LoanRequestError |
  RefinanceRequestSuccess |
  RefinanceRequestError |
  DeleteLoanRequest |
  DeleteLoanRequestSuccess |
  OpenEditPopup |
  CloseUserPopup |
  SetCurrentRefinanceOptions |
  ChangeEmail |
  ChangeEmailSuccess |
  ChangeEmailError |
  ConfirmEmailChange |
  ChangePhone |
  ChangePhoneSuccess |
  ChangePhoneError |
  ConfirmPhoneChange |
  RequestVerificationCodeAgain |
  SetChangingState |
  ToggleMarketingSubscription |
  LoadIncomeDocumentsSuccess |
  MarketingSubscriptionSuccess |
  MarketingSubscriptionError |
  ChangeUserPassword |
  CreateUserPassword |
  ChangeUserPasswordSuccess |
  LoadLoanHistory |
  SetLoanHistory |
  LoadLoanError |
  AffiliateCompleteRegistration |
  AffiliateCompleteRegistrationSuccess |
  LoanSmsVerification |
  LoanSmsVerificationSuccess |
  LoanSmsVerificationError |
  SetLoanSmsVerificationStatus |
  SetLoanVerificationFormSubmittedStatus |
  RequestSmsCode |
  RequestSmsCodeSuccess |
  RequestSmsError |
  CleanUser |
  LoadUserNotifications |
  SetUserNotifications |
  CallConfirmLoanNotification |
  LoanVerificationRequested |
  LoadIncomeDocuments |
  SetLoanHistoryLoadedStatus |
  SetLoanConfirmationSmsRequestedStatus |
  BeforeRequestLoan |
  DeleteDocument |
  BeforeRequestLoanSuccess |
  LoanRequestStatusClear  |
  ChooseExtensionLoanType |
  SubmitFeedback;

