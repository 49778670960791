import { Component, Input, OnInit } from '@angular/core';
import {AppState} from '../../../../store/state';
import {Store} from '@ngrx/store';
import {CloseModal, OpenModal} from '../../../../store/actions';
import { ModalTypes } from 'src/app/enums';
import { parentPageContent } from 'src/app/store/selectors/info.selectors';
import { SetContentPage } from 'src/app/store/actions/info.actions';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @Input()
  public closeBtnFixed = false;
  public parent: ModalTypes;

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    this.store.select(parentPageContent)
    .subscribe(data => {
      this.parent = data;
    });
  }

  public close() {

    if (this.parent !== ModalTypes.None)  {
      this.store.dispatch(new OpenModal(this.parent));
      this.store.dispatch(new SetContentPage({page: '', parent: ModalTypes.None}));
    } else {
      this.store.dispatch(new CloseModal());
    }
  }

}
