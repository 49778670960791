import {Directive, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, ElementRef, OnInit, Input} from '@angular/core';
import {FormGroup, NgControl} from '@angular/forms';
import {ValidationComponent} from '../components/validation/validation.component';

@Directive({
  selector: '[appAddValidation]'
})
export class AddValidationDirective implements OnInit {
  @Input('appAddValidation') classes: string;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private injector: Injector,
              private el: ElementRef,
              private formControl: NgControl) {
  }

  private form: FormGroup;

  ngOnInit(): void {
    this.form = this.formControl['formDirective']['form'];
    this.appendComponentToBody();
  }

  private appendComponentToBody() {
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(ValidationComponent)
      .create(this.injector);
    const com = componentRef.instance as ValidationComponent;
    com.formGroup = this.form;
    com.formControlName = this.formControl.name;
    com.classes = this.classes;
    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    this.el.nativeElement.parentNode.appendChild(domElem);
  }
}
