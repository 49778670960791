import { NgModule } from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {NoAuthGuard} from './guards/auth.guard';
import {NotFoundPageComponent} from './modules/static-pages/not-found-page/not-found-page.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/main-page/main-page.module').then(m => m.MainPageModule),
  },
  {
    path: '',
    loadChildren: () => import('./modules/static-pages/static-pages.module').then(m => m.StaticPagesModule),
  },
  {
    path: 'profil',
    loadChildren: () => import('./modules/user-panel/user-panel.module').then(m => m.UserPanelModule),
    canActivate: [NoAuthGuard]
  },
  {path: '404', component: NotFoundPageComponent},
  {path: '**', redirectTo: '/404'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      // preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
