import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NavigationItem} from '../../../models';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit {
  @Input()
  public menuItems: NavigationItem[];
  @Output()
  public loginClicked: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  public login(event) {
    this.loginClicked.emit(event);
  }

  public enableScroll() {
    document.body.classList.remove('no-scroll');
  }

}
