import {AppState} from '../state';
import {createSelector} from '@ngrx/store';

export const selectAuthenticationStatus = (state: AppState) => state.auth.isAuthenticated;

export const selectAuthenticatedUserId = (state: AppState) => state.auth.id;

export const selectAuthenticatedToken = (state: AppState) => state.auth.token;

export const selectAuthenticationError = (state: AppState) => state.auth.error;

export const selectRegistrationData = (state: AppState) => state.auth && state.auth.registration;

export const selectRegistrationIntervalData = createSelector(
  selectRegistrationData,
  (registrationData) => registrationData && registrationData.intervalData
);

export const selectRegErrors = createSelector(
  selectRegistrationData,
  (registrationData) => registrationData && registrationData.errors
);

export const selectRegistrationStep = createSelector(
  selectRegistrationData,
  (registration) => registration && registration.step
);

export const selectRestorePassState = (state: AppState) => state.auth.restorePass && state.auth.restorePass.state;

export const selectRestorePassError = (state: AppState) => state.auth.restorePass && state.auth.restorePass.errors;

export const selectAuthOrRegErrors = (state: AppState) => state.auth && state.auth.authOrRegErrors;
