import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NavigationItem} from '../../../models';
import {AuthService, DataService} from '../../services';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../store/state';
import {LogOut, OpenModal} from '../../store/actions';
import {ModalTypes} from '../../enums';
import {Observable} from 'rxjs';
import {selectBankConfirmationsStatus, selectUsername, selectUserNotifications} from '../../store/selectors';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input()
  public accountMenuItems: NavigationItem[];
  @Input()
  public menuItems: NavigationItem[];

  public isAuthorized = this.auth.isAuthorized$;
  public accountName$: Observable<string>;
  public userNotifications$: Observable<any[]>;
  public bankConfirmed$: Observable<boolean>;

  constructor(private dataService: DataService,
              private auth: AuthService,
              private router: Router,
              private store: Store<AppState>) {
    this.bankConfirmed$ = this.store.select(selectBankConfirmationsStatus);
    this.accountName$ = this.store.select(selectUsername);
    this.userNotifications$ = this.store.select(selectUserNotifications);
  }

  ngOnInit() {
  }

  public login() {
    if (this.router.url === '/logowanie') {
      this.store.dispatch(new OpenModal(ModalTypes.LoginModal));
    } else {
      this.router.navigateByUrl('/logowanie');
    }
  }

  public logout() {
    this.store.dispatch(new LogOut());
  }

  public goToNotifications() {
    this.router.navigate(['/profil/notifications-list']);
  }

  public isConfirmOfferPage() {
    return window.location.href.includes('/profil/potwierdz-wniosek');
  }

  public isConsentsPage() {
    return window.location.href.includes('/profil/consents');
  }

  public disableScroll() {
    document.body.classList.add('no-scroll');
  }
}
