import {AuthState, initialAuthenticationState} from '../state/authentication.state';
import {AuthenticationActions, AuthenticationActionTypes} from '../actions';
import {ChangingStateTypes} from '../../modules/user-panel/models/changing-state-types';
import { environment } from 'src/environments/environment';
import { globalVariables } from 'src/environments/globalVariables';

export function authenticationReducer(state: AuthState = initialAuthenticationState, action: AuthenticationActions): AuthState {
  switch (action.type) {
    case AuthenticationActionTypes.SetAuthenticationStatus:
      const token = action.payload.token;

      if (navigator.cookieEnabled) {
        token ?
        localStorage.setItem('auth_token', token) :
        localStorage.removeItem('auth_token');

        token ?
        localStorage.setItem('expired_token', (String)((new Date()).getTime() + environment.tokenExpireTime)) :
        localStorage.removeItem('expired_token');
      } else {
        globalVariables.auth_token = token ? token : null;

        globalVariables.expired_token = token ? (String)((new Date()).getTime() + environment.tokenExpireTime) : null;
      }

      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        token,
      };
    case AuthenticationActionTypes.SetAuthenticationError:
      return {
        ...state,
        error: action.payload.error
      };
    case AuthenticationActionTypes.SetAuthenticationUserData:
      return {
        ...state,
        ...action.payload
      };
    case AuthenticationActionTypes.LogOut:
      if (navigator.cookieEnabled) {
        window.localStorage.clear();
        localStorage.clear();
      }
      return {
        ...state,
        id: null,
        token: null,
        isAuthenticated: false
      };
    case AuthenticationActionTypes.SignUpStep1:
      return {
        ...state,
        registration: {...action.payload}
      };
    case AuthenticationActionTypes.SignSuccess:
      return {
        ...state,
        registration: {...state.registration, step1Success: true}
      };
    case AuthenticationActionTypes.SetSignUpIntervalData:
      return {
        ...state,
        registration: Object.assign({}, {...state.registration, intervalData: {...action.payload}, step: 2}),
        authOrRegErrors: []
      };
    case AuthenticationActionTypes.SignError:
      return {
        ...state,
        registration: Object.assign({}, {...state.registration, errors: action.payload.errMessage})
      };
    case AuthenticationActionTypes.RestorePassSuccess:
      return {
        ...state,
        restorePass: {state: ChangingStateTypes.Success}
      };
    case AuthenticationActionTypes.RestorePassError:
      return {
        ...state,
        restorePass: {...state.restorePass, state: ChangingStateTypes.Main, errors: {msg: action.payload.errMessage}}
      };
    case AuthenticationActionTypes.SetRestorePassState:
      return {
        ...state,
        restorePass: {...state.restorePass, state: action.state}
      };
    case AuthenticationActionTypes.ResetAuthErrors:
      return {
        ...state,
        restorePass: {...state.restorePass, errors: null},
        registration: {...state.registration, errors: null},
        error: null
      };
    case AuthenticationActionTypes.SetRegistrationStep:
      return {
        ...state,
        registration: Object.assign({}, {...state.registration, step: action.step})
      };
    case AuthenticationActionTypes.SetFormFieldErrors:
      return {
        ...state,
        authOrRegErrors: action.payload,
      };
    default:
      return state;
  }
}
