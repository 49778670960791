import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { SecureHttpClientService } from 'src/app/services/secure-http-client.service';
import { Options } from 'ng5-slider';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { AppState } from '../../../../store/state';
import { Store } from '@ngrx/store';
import {
  LoadConditions,
  OpenModal,
  SetCalculatorAmount,
  SetCalculatorCommission,
  SetCalculatorTerm,
  SetFormularDoc
} from '../../../../store/actions';
import { selectDateTime, selectExactConditions, selectUser } from '../../../../store/selectors';
import { BehaviorSubject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { addDays, debounce } from '../../../../utils';
import { ModalTypes } from '../../../../enums';
import { selectCalculatorData } from '../../../../store/selectors/info.selectors';
import { FileService } from '../../../../services/file.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'confirm-incomes-expenses',
  templateUrl: './confirm-incomes-expenses.component.html',
  styleUrls: ['./confirm-incomes-expenses.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ConfirmIncomesExpensesComponent implements OnInit {
  public currency = 'zł';

  @Input()
  public updateExpenses?: boolean;
  @Input()
  public updateIncome?: boolean;

  @Output()
  public confirmClicked: EventEmitter<any> = new EventEmitter<any>();

  public amountIncomeInfo = 'Wpisz kwotę miesięcznego dochodu netto';
  public amountExpenseInfo = 'Wpisz kwotę miesięcznych wydatków gospodarstwa domowego';

  public isSubmitted: boolean = false;
  public confirmForm: FormGroup;

  public maturityDate?: string;

  public amount?: number;
  public term?: number;
  public comm?: number;
  public interest?: number;
  public rrso?: number;


  public today = new Date();

  constructor(private frmBuilder: FormBuilder,
    private store: Store<AppState>) {
  }

  ngOnInit() {
    this.confirmForm = this.frmBuilder.group({
      amountIncome: ['', [Validators.required, Validators.pattern(/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\,\d{1,2})?$/)]],
      amountExpense: ['', [Validators.required, Validators.pattern(/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\,\d{1,2})?$/)]],
    });

    this.store.dispatch(new LoadConditions());

    this.store.select(selectCalculatorData)
    .subscribe(data => {
      if (data && data.amount && data.term) {
         this.amount = data.amount;
         this.term = data.term;
         let today = new Date();
         this.maturityDate = addDays(today, this.term).toDateString()
      }
    });

    this.store.select(selectExactConditions)
      .subscribe(data => {
         this.interest = Number(data.interest);
         this.comm = data.commissionSecondLoan ? data.commissionSecondLoan : data.commission;
         this.rrso = data.rrso;
      });

      this.store.select(selectUser)
        .subscribe(data => {
          this.confirmForm.controls.amountExpense.setValue(data.fullUserData.expenses.toString().replace('.',','));
          this.confirmForm.controls.amountIncome.setValue(data.fullUserData.income.toString().replace('.',','));
        });

  }

  public Submit() {
    this.isSubmitted = true;

  if (this.confirmForm.valid)
    this.confirmClicked.emit({
      Income: this.confirmForm.controls.amountIncome.value,
      Expense: this.confirmForm.controls.amountExpense.value
    });
  }
}

